import { useQuery } from "@apollo/client";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Event } from "../../app/types";
import { GET_EVENT } from "../event/queries";
import Logo from "./logo.png";

export function Header() {
  const { eventCode } = useParams();
  const { data } = useQuery(GET_EVENT, {
    fetchPolicy: "cache-only",
    variables: { code: eventCode },
  });
  const event: Event = data ? data.event : null;
  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar position="static">
        <Toolbar>
          <Box mr={1}>
            <img src={Logo} alt="logo" />
          </Box>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/"
              sx={{ color: "white", textDecoration: "none" }}
              underline="hover"
            >
              Speaking List
            </Link>
            {window.location.hostname.match(/^beta\./) && (
              <Chip
                size="small"
                color="error"
                sx={{
                  ml: 1,
                  fontWeight: "bold",
                }}
                label="beta"
              />
            )}
          </Typography>
          {event && (
            <Link
              component={RouterLink}
              to={`/e/${event.code}`}
              sx={{ color: "white", textDecoration: "none" }}
              underline="hover"
            >
              {event.title}
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
