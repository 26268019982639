import acceptLanguage from "accept-language";
import { useEffect, useState } from "react";
import { Participant, Turn } from "./types";

export function getPendingTurn(participant: Participant) {
  const pendingTurns = participant.turns.filter(
    (turn) => !turn.startedAt && !turn.endedAt
  );
  return pendingTurns.length > 0 ? pendingTurns[0] : null;
}

export function getCurrentTurn(participant: Participant) {
  const currentTurns = participant.turns.filter(
    (turn) => turn.startedAt && !turn.endedAt
  );
  return currentTurns.length > 0 ? currentTurns[0] : null;
}

export function hasPendingTurn(participant: Participant) {
  return getPendingTurn(participant) !== null;
}

export function hasCurrentTurn(participant: Participant) {
  return getCurrentTurn(participant) !== null;
}

export function getPreviousTurns(participant: Participant) {
  return (participant.turns || []).filter(
    (turn) => !!turn.startedAt && !!turn.endedAt
  );
}

export function getTurnDuration(turn: Turn) {
  if (!turn.endedAt || !turn.startedAt) {
    return null;
  }
  return Math.round(
    (Date.parse(turn.endedAt) - Date.parse(turn.startedAt)) / 1000
  );
}

export function getSpokenTime(participant: Participant) {
  return getPreviousTurns(participant).reduce(
    (accumulator, turn) => accumulator + (getTurnDuration(turn) || 0),
    0
  );
}

export function durationToText(duration: number) {
  const hours = Math.trunc(duration / 3600);
  const minutes = Math.trunc((duration % 3600) / 60);
  const seconds = Math.trunc(duration % 60);
  let result = "";
  if (hours) {
    result += `${hours}h`;
  }
  if (minutes) {
    let minutesText = minutes.toString();
    if (result) {
      result += " ";
      minutesText = minutesText.padStart(2, "0");
    }
    result += `${minutesText}m`;
  }
  if (seconds || !result) {
    let secondsText = seconds.toString();
    if (result) {
      result += " ";
      secondsText = secondsText.padStart(2, "0");
    }
    result += `${secondsText}s`;
  }
  return result;
}

export function getLocale() {
  const language =
    (navigator.languages && navigator.languages[0]) || navigator.language;
  return language.toLowerCase().split(/[_-]+/)[0];
}

export function useI18nMessages() {
  acceptLanguage.languages(["en", "fr"]);
  const language =
    (navigator.languages && navigator.languages[0]) || navigator.language;
  const locale = acceptLanguage.get(language) || "en";
  const [messages, setMessages] = useState<{ [key: string]: any } | null>(null);
  useEffect(() => {
    async function getMessage() {
      switch (locale) {
        case "fr":
          return import("../lang/compiled/fr.json");
        default:
          return import("../lang/compiled/en.json");
      }
    }
    getMessage().then((messages) => setMessages(messages));
  }, [locale]);
  return { locale, messages };
}
