import { useMutation } from "@apollo/client";
import HowToIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ErrorAlert } from "../error/Error";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import Page from "../page/Page";
import { CREATE_EVENT } from "./queries";

export function Home() {
  return (
    <Page>
      <Container
        maxWidth="sm"
        sx={{
          "& h1": {
            mt: 4,
          },
          "& h3": {
            mt: 6,
          },
        }}
      >
        <Header />
        <Introduction />
        <GoToEvent />
        <IntroOrganize />
        <CreateEvent />
        <HowToLink />
        <IntroSocialCategories />
        <IntroIntegrations />
        <Footer />
      </Container>
    </Page>
  );
}

function HowToLink() {
  return (
    <p>
      <Link component={RouterLink} to="/howto" underline="hover">
        <HowToIcon
          fontSize="small"
          sx={{
            verticalAlign: "bottom",
            mr: 0.5,
          }}
        />
        <FormattedMessage defaultMessage="How does this work?" />
      </Link>
    </p>
  );
}

function Introduction() {
  return (
    <React.Fragment>
      <h1>
        <FormattedMessage defaultMessage="Manage speaking lists for your online events!" />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="
          When online video calls reach a certain number of participants, it
          becomes harder and harder to listen to one another and distribute
          speaking turns fairly.
        "
        />
      </p>
      <HowToLink />
    </React.Fragment>
  );
}

function IntroOrganize() {
  return (
    <React.Fragment>
      <h3>
        <FormattedMessage defaultMessage="Organize" />
      </h3>
      <p>
        <FormattedMessage
          defaultMessage="
          Speaking List helps you organize speaking turns in your online events by
          letting participants ask for a turn, and be notified in real time when
          their turn to speak has arrived. As an event organizer, you can manage
          the waiting list, reorder it, start and end turns, etc.
        "
        />
      </p>
    </React.Fragment>
  );
}

function IntroSocialCategories() {
  return (
    <React.Fragment>
      <h3>
        <FormattedMessage defaultMessage="Be fairer" />
      </h3>
      <p>
        <FormattedMessage
          defaultMessage="
          People in marginalized groups often get less opportunities to speak. The
          application will allow participants to declare which social categories
          they belong to, and organizers will be able to take that information
          into account when ordering the waiting list. They will also get
          statistics of speaking opportunities and durations by category.
        "
        />
      </p>
    </React.Fragment>
  );
}

function IntroIntegrations() {
  return (
    <React.Fragment>
      <h3>
        <FormattedMessage defaultMessage="Integrations" />
      </h3>
      <p>
        <FormattedMessage
          defaultMessage="Speaking List can be integrated with Discord! See <link>the integrations page</link> to learn how you can use it in your server."
          values={{
            link: (chunks: React.ReactNode[]) => (
              <Link component={RouterLink} to="/integrations" underline="hover">
                {chunks}
              </Link>
            ),
          }}
        />
      </p>
    </React.Fragment>
  );
}

function GoToEvent() {
  const [eventCode, setEventCode] = useState("");
  const navigate = useNavigate();
  const intl = useIntl();
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (eventCode.trim()) {
      navigate(`e/${eventCode.trim()}`);
    }
  };
  return (
    <ActionBox
      title={<FormattedMessage defaultMessage="Join a meeting" />}
      subTitle={<FormattedMessage defaultMessage="Enter the meeting code:" />}
      onSubmit={onSubmit}
      formContent={
        <>
          <TextField
            variant="standard"
            name="eventcode"
            value={eventCode}
            placeholder={intl.formatMessage({ defaultMessage: "Event Code" })}
            size="small"
            onChange={(e) => {
              setEventCode(e.target.value.toUpperCase());
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!eventCode.trim()}
          >
            <FormattedMessage defaultMessage="Go to event" />
          </Button>
        </>
      }
    />
  );
}

function CreateEvent() {
  const [eventTitle, setEventTitle] = useState("");
  const [mutate, { loading, error }] = useMutation(CREATE_EVENT);
  const navigate = useNavigate();
  const intl = useIntl();
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate({ variables: { title: eventTitle.trim() } }).then((result) => {
      const eventCode = result.data.createEvent.event.code;
      navigate(`e/${eventCode}/admin`);
    });
  };
  return (
    <ActionBox
      title={<FormattedMessage defaultMessage="Create a new event" />}
      subTitle={<FormattedMessage defaultMessage="Choose a title:" />}
      onSubmit={onSubmit}
      formContent={
        <>
          <TextField
            variant="standard"
            name="eventtitle"
            value={eventTitle}
            placeholder={intl.formatMessage({ defaultMessage: "Event Title" })}
            size="small"
            onChange={(e) => {
              setEventTitle(e.target.value);
            }}
          />
          <Button
            disabled={!eventTitle.trim() || loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            <FormattedMessage defaultMessage="Create" />
          </Button>
          <ErrorAlert error={error} />
        </>
      }
    />
  );
}

interface ActionBoxProps {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  formContent: React.ReactNode;
}
function ActionBox(props: ActionBoxProps) {
  return (
    <Paper
      sx={{
        mt: 4,
        mb: 4,
        p: 2,
      }}
      elevation={4}
    >
      <Box
        component="p"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "0px",
        }}
      >
        {props.title}
      </Box>
      <p>{props.subTitle}</p>
      <Box
        component="form"
        onSubmit={props.onSubmit}
        sx={{ "& button": { ml: 2 }, display: "flex", alignItems: "center" }}
      >
        {props.formContent}
      </Box>
    </Paper>
  );
}
