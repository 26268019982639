import Container from "@mui/material/Container";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";

export function PageNotFound() {
  return (
    <Container maxWidth="sm">
      <Header />
      <h3>
        <FormattedMessage defaultMessage="This page does not exist!" />
      </h3>
      <p>
        <FormattedMessage
          defaultMessage="Go back <link>to the home page</link>."
          values={{
            link: (chunks: React.ReactNode[]) => <Link to="/">{chunks}</Link>,
          }}
        />
      </p>
      <Footer />
    </Container>
  );
}
