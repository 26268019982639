import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from "@mitresthen/matomo-tracker-react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function WithMatomo(props: { children: React.ReactNode }) {
  const instance = process.env.REACT_APP_MATOMO_URL
    ? createInstance({
        urlBase: process.env.REACT_APP_MATOMO_URL,
        siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID || "1", 10),
        trackerUrl: process.env.REACT_APP_MATOMO_URL + "js/",
        srcUrl: process.env.REACT_APP_MATOMO_URL + "js/",
        disabled: !!process.env.REACT_APP_MATOMO_URL,
      })
    : null;
  return instance ? (
    <MatomoProvider value={instance}>
      <TrackPage>{props.children}</TrackPage>
    </MatomoProvider>
  ) : (
    <>{props.children}</>
  );
}

function TrackPage(props: { children: React.ReactNode }) {
  const location = useLocation();
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return <>{props.children}</>;
}
