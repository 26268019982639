import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation CreateEvent($title: String!) {
    createEvent(title: $title) {
      event {
        code
      }
      me {
        token
        organizer
      }
    }
  }
`;
