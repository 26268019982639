import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { settings } from "../../app/config";

export function Footer() {
  return (
    <Box
      sx={{
        mt: 8,
        pt: 1,
        borderTop: "1px solid #ccc",
        color: "text.secondary",
        fontSize: "80%",
        textAlign: "center",
      }}
    >
      <Box component="p" my={0.5}>
        <FormattedMessage
          defaultMessage="
            This application is opensource, under the <agpllink>AGPL v3</agpllink> license.
            See the <sourcelink>source code</sourcelink>.
            Report <issueslink>issues</issueslink>.
          "
          values={{
            agpllink: (chunks: React.ReactNode[]) => (
              <Link
                href="https://www.gnu.org/licenses/agpl-3.0.html"
                underline="hover"
              >
                {chunks}
              </Link>
            ),
            sourcelink: (chunks: React.ReactNode[]) => (
              <Link
                href="https://gitlab.com/abompard/speakinglist"
                underline="hover"
              >
                {chunks}
              </Link>
            ),
            issueslink: (chunks: React.ReactNode[]) => (
              <Link
                href="https://gitlab.com/abompard/speakinglist/-/issues"
                underline="hover"
              >
                {chunks}
              </Link>
            ),
          }}
        />{" "}
        <FormattedMessage
          defaultMessage="
            Read the <link>Privacy Policy and Terms of Service</link>.
          "
          values={{
            link: (chunks: React.ReactNode[]) => (
              <Link component={RouterLink} to="/privacy" underline="hover">
                {chunks}
              </Link>
            ),
          }}
        />
      </Box>
      <Box component="p" my={0.5}>
        <FormattedMessage
          defaultMessage="
            If you want to contact the administrators to ask a question or to have your data deleted,
            please <emaillink>send an email</emaillink>.
          "
          values={{
            emaillink: (chunks: React.ReactNode[]) => (
              <Link href={`mailto:${settings.ADMIN_EMAIL}`} underline="hover">
                {chunks}
              </Link>
            ),
          }}
        />
      </Box>
    </Box>
  );
}
