import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { settings } from "./config";

export function makeClient() {
  const cache = new InMemoryCache({
    typePolicies: {
      // Type policy map
      Me: {
        keyFields: [],
        fields: {
          token: {
            // Field policy for the token field
            read() {
              // The read function for the token field
              console.log(
                "get token from localStorage",
                localStorage.getItem("token")
              );
              return localStorage.getItem("token");
            },
            merge: (existing: string = "", incoming: string) => {
              localStorage.setItem("token", incoming || "");
              return incoming;
            },
          },
          participants: {
            merge: false,
          },
          /*
                        participantId: { // Field policy for the token field
                            read(_) { // The read function for the token field
                                return participantIdVar();
                                //return localStorage.getItem('participantId');
                            }
                        }
                        */
        },
      },
      Participant: {
        fields: {
          turns: {
            merge: false,
          },
        },
      },
      Event: {
        keyFields: ["code"],
        fields: {
          participants: {
            merge: false,
          },
          turns: {
            merge: false,
          },
          socialCategories: {
            merge: false,
          },
        },
      },
    },
  });

  // const initData = {
  //     {/* your initial data */}
  // };
  // cache.writeData({ data: initData })

  const httpLink = new HttpLink({
    uri: settings.GRAPHQL_URL,
  });
  const wsLink = new WebSocketLink({
    uri: settings.WEBSOCKET_URL,
    options: {
      reconnect: true,
      connectionParams: () => {
        const token = localStorage.getItem("token");
        return token ? { authToken: token } : {};
      },
    },
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  // Authentication
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  // Error reporting
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const locationsText = !locations
          ? ""
          : locations?.map((l) => `L${l.line}C${l.column}`).join(", ");
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locationsText}, Path: ${path}`
        );
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(splitLink)),
    cache,
  });

  // Return a promise for when we'll use apollo-cache-persist
  return Promise.resolve(client);

  /*
    return persistCache({
      cache,
      storage: window.localStorage
    }).then(() => {
      client.onResetStore(async () => cache.writeData({ data: initData }));
      return client;
    });
    */
}
