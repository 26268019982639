import React from "react";

interface PageProps {
  title?: string[] | string;
  children: React.ReactNode;
}
export default function Page(props: PageProps) {
  const baseName = "SpeakingList";
  React.useEffect(() => {
    let title: string[] = [];
    if (props.title) {
      if (!Array.isArray(props.title)) {
        title = [props.title];
      } else {
        title = props.title;
      }
    }
    title.push(baseName);
    document.title = title.join(" – ");
  }, [props.title]);
  return <>{props.children}</>;
}
