import LoadingIcon from "@mui/icons-material/HourglassEmpty";
import Box from "@mui/material/Box";
import React from "react";
import Spinner from "./spinner.gif";

export function LoadingIndicator() {
  return (
    <Box
      position="absolute"
      sx={{
        background: "white",
        zIndex: 1,
        marginLeft: "-4px",
      }}
    >
      <LoadingIcon sx={{ verticalAlign: "middle", fontSize: "1rem" }} />
    </Box>
  );
}

interface LoadingProps {
  children?: React.ReactNode;
}
export function Loading(props: LoadingProps) {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "1em 0",
        color: "#777",
      }}
    >
      <Box
        component="img"
        src={Spinner}
        alt="loading..."
        sx={{ verticalAlign: "middle", marginRight: "0.5em" }}
      />
      {props.children}
    </Box>
  );
}
