const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const settings = {
  PUBLIC_URL: process.env.PUBLIC_URL || "http://localhost:3000",
  API_URL,
  GRAPHQL_URL: API_URL + "/graphql/",
  WEBSOCKET_URL: (API_URL + "/ws/").replace(/^http/, "ws"),
  // WEBSOCKET_URL: "ws://localhost:8000/ws",
  WEBSOCKET_RECONNECT_INTERVAL: 0,
  //WEBSOCKET_PING_INTERVAL: 30,
  WEBSOCKET_PING_INTERVAL: 5,
  ADMIN_EMAIL: process.env.ADMIN_URL || "contact@speakinglist.net",
};
