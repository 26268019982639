import { gql } from "@apollo/client";

export const GET_EVENT = gql`
  query GetEvent($code: ID!) {
    event(code: $code) {
      code
      title
      startedAt
      endedAt
      isPaused
      turnMaxDuration
      socialCategories {
        id
        name
      }
    }
  }
`;

export const GET_EVENT_ORGA = gql`
  query GetEvent($code: ID!) {
    event(code: $code) {
      code
      title
      isPaused
      startedAt
      endedAt
      expiresAt
      duration
      adminCode
      turnMaxDuration
      socialCategories {
        id
        name
      }
    }
  }
`;

export const SUBSCRIBE_EVENT = gql`
  subscription OnEventChanged($code: ID!) {
    event(code: $code) {
      code
      title
      startedAt
      endedAt
      expiresAt
      isPaused
      duration
      turnMaxDuration
      socialCategories {
        id
        name
      }
    }
  }
`;
