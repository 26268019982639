import { ApolloError } from "@apollo/client";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { FormattedMessage } from "react-intl";

interface ErrorAlertProps {
  error?: ApolloError;
}
export function ErrorAlert({ error }: ErrorAlertProps) {
  if (!error) return null;
  return (
    <Box mt={1}>
      <Alert severity="error">
        <FormattedMessage
          defaultMessage="Error: {message}"
          values={{ message: error.message }}
        />
      </Alert>
    </Box>
  );
}
