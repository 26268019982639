import { ApolloClient, ApolloProvider } from "@apollo/client";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-datetimeformat/locale-data/fr";
import { grey } from "@mui/material/colors";
import {
  StyledEngineProvider,
  ThemeProvider,
  //adaptV4Theme,
  createTheme,
} from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import "./App.css";
import { makeClient } from "./app/client";
import { useI18nMessages } from "./app/utils";
import { AutoScrollToTop } from "./features/auto-scroll-to-top/AutoScrollToTop";
import { Home } from "./features/home/Home";
import { Loading } from "./features/loading/Loading";
import { WithMatomo } from "./features/matomo/WithMatomo";
import { PageNotFound } from "./features/page-not-found/PageNotFound";

const Member = React.lazy(() => import("./member/Member"));
const Organizer = React.lazy(() => import("./organizer/Organizer"));
const HowTo = React.lazy(() => import("./features/howto/HowTo"));
const PrivacyPolicy = React.lazy(
  () => import("./features/privacy-policy/PrivacyPolicy")
);
const Integrations = React.lazy(
  () => import("./features/integrations/Integrations")
);

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const theme = createTheme({
  palette: {
    neutral: {
      main: grey[700],
      contrastText: "#fff",
    },
  },
});

function App() {
  const [client, setClient] = useState<ApolloClient<any> | undefined>(
    undefined
  );
  useEffect(() => {
    makeClient().then((c) => setClient(c));
  }, []);
  if (client === undefined) return <Loading />;
  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

function Root() {
  const { locale, messages } = useI18nMessages();
  if (messages === null) return <Loading />;
  return (
    <Router>
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={messages}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION") {
            console.log("Missing translation", err.message);
            return;
          }
          throw err;
        }}
      >
        <WithMatomo>
          <AutoScrollToTop />
          <React.Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="howto" element={<HowTo />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="e/:eventCode/admin/*" element={<Organizer />} />
              <Route path="e/:eventCode/*" element={<Member />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </React.Suspense>
        </WithMatomo>
      </IntlProvider>
    </Router>
  );
}

export default App;
